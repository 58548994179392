import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, LinkBox, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				CycleCare Pro
			</title>
			<meta name={"description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:title"} content={"CycleCare Pro"} />
			<meta property={"og:description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4">
				0677324809
			</Override>
			<Override slot="link3">
				вул. І. Миколайчука, м. Львів
			</Override>
		</Components.Header2>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,#0c1128 100%),url(https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-1.jpg?v=2024-06-24T13:31:01.528Z) 0% 0%/cover">
			<Override slot="text">
				Наш спектр послуг
			</Override>
			<Override slot="text1">
				Комплексний догляд за велосипедом для кожного водія
			</Override>
			<Override slot="box3" />
			<Override slot="box1" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="text3">
				вул. І. Миколайчука, м. Львів
			</Override>
			<Override slot="text2">
				0677324809
			</Override>
		</Components.Hero>
		<Section
			padding="80px 0 90px 0"
			quarkly-title="Headline-4"
			border-width="0 0 .1px 0"
			border-style="solid"
			border-color="--color-darkL2"
		>
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="block" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--lead"
						color="--dark"
						sm-margin="0px 0px 25px 0px"
						width="100%"
					>
						У CycleCare Pro ми пишаємося тим, що пропонуємо широкий спектр послуг, розроблених для забезпечення найкращої роботи вашого велосипеда. Наша команда кваліфікованих техніків прагне забезпечити винятковий догляд за кожним типом велосипеда, забезпечуючи плавну та безпечну їзду. Незалежно від того, користуєтеся ви щоденними поїздками чи любителями вихідних, наші послуги створені відповідно до ваших потреб.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text
					margin="0px 0px 25px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					Огляд послуг
				</Text>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base" width="70%">
					У CycleCare Pro ми пропонуємо різноманітні послуги, щоб задовольнити всі ваші велосипедні потреби. Ось деякі з основних послуг, які ми надаємо:
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				align-content="center"
				align-items="center"
				justify-items="center"
				justify-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					background="--color-darkL2"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Звичайні налаштування
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Базове налаштування: Включає регулювання гальм, налаштування передач і ретельну перевірку безпеки.
Розширене налаштування: включає повний огляд, змащення всіх рухомих частин і детальне очищення.
Налаштування продуктивності: Ідеально підходить для високопродуктивних велосипедів, забезпечуючи оптимальну швидкість і ефективність.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Гальмівні послуги
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Регулювання гальм: Точне налаштування гальм для максимальної гальмівної сили та безпеки.
Заміна гальмівних колодок: Встановлення високоякісних гальмівних колодок для покращення ефективності гальмування.
Обслуговування гідравлічних гальм: Обслуговування та ремонт гідравлічних гальмівних систем для безперебійної роботи.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Оптимізація перемикання передач
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Регулювання передач: Точне налаштування передач вашого велосипеда для плавного перемикання.
Вирівнювання перемикача передач:  Переконайтеся, що ваш перемикач ідеально вирівняний для точного перемикання передач.
Заміна кабелю: заміна зношених кабелів для плавного та надійного перемикання передач.
						</Text>
					</LinkBox>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Послуги для коліс
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Регулювання коліс: Виправляє будь-яке коливання коліс для рівної та справжньої їзди.
Заміна спиць: заміна пошкоджених спиць для збереження цілісності колеса.
Капітальний ремонт втулки: Очищення та змащення втулок для забезпечення плавного обертання.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Повний капітальний ремонт
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Частковий капітальний ремонт: Зосереджено на ключових компонентах, таких як трансмісія та гальма, для цілеспрямованого вдосконалення.
Повний ремонт: комплексне обслуговування, що охоплює всі аспекти вашого велосипеда для продуктивності як у нового.
Капітальний ремонт підвіски: Спеціалізоване обслуговування велосипедів із системами підвіски, що забезпечує плавну їзду по пересіченій місцевості.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Користувацькі збірки та оновлення
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Індивідуальні конструкції велосипедів: Створення велосипеда відповідно до ваших уподобань і стилю їзди.
Оновлення компонентів: Підвищення продуктивності вашого велосипеда за допомогою високоякісних компонентів.
Індивідуальне налаштування: Регулювання вашого велосипеда для максимального комфорту та ефективності на основі ваших розмірів тіла та звичок їзди.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" md-margin="0px 0px 30px 0px">
					Зв’яжіться з нами для отримання додаткової інформації
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					<Strong>
						Відкрийте для себе повний спектр наших пропозицій, зв’язавшись з нами сьогодні. Наш привітний та кваліфікований персонал завжди готовий відповісти на ваші запитання та надати більш детальну інформацію про наші послуги. Завітайте до нас або зателефонуйте нам, щоб дізнатися більше про те, як ми можемо допомогти вам підтримувати ваш велосипед у відмінному стані.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--headline3">
				Відчуйте різницю CycleCare Pro – де продуктивність вашого велосипеда є нашим пріоритетом.
			</Override>
			<Override slot="text1">
				Зв’яжіться з нами, і ми зробимо вашу поїздку безперебійною та безпечною.
				<br />
				<br />
				<br />
				CycleCare Pro – ваш партнер у кожному натисканні педалі!
			</Override>
		</Components.Cta>
		<Components.Header2>
			<Override slot="text">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					CycleCare Pro
				</Strong>
			</Override>
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});